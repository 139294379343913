<template>
  <div>
    <promotion-form :save="save" class="mt-10" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PromotionForm from './promotion-form'

export default {
  name: 'PromotionAdd',
  components: {PromotionForm},
  computed: {},
  async created() {
    document.title = 'Создание акции'
    await this.resetForm()
  },
  methods: {
    async save(image) {
      await this.create(image)
      this.$router.back()
    },
    ...mapActions('promotion/form', ['create', 'resetForm']),
  },
}
</script>

